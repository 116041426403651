import { graphql } from "gatsby"
import React from "react"
import PropTypes from "prop-types"

import OneColumnLandingPage from "./one-column"
import TwoColumnLandingPage from "./two-column"
import TwoColumnDarkHeaderLandingPage from "./two-column-dark-header"

import Layout from "../../layouts"
import Header from "../../components/header"
import Footer from "../../components/footer"
import SEO from "../../components/seo"

const layoutToTemplate = {
  "One Column": OneColumnLandingPage,
  "One Column Full Width Image": OneColumnLandingPage,
  "Two Columns": TwoColumnLandingPage,
  "Two Columns Dark Header": TwoColumnDarkHeaderLandingPage,
}

const layoutToInverted = layout => {
  if (layout === `Two Columns Dark Header`) return true
  return false
}

const LandingPage = ({ data, location }) => {
  const { contentfulLandingPage } = data
  const { layout } = contentfulLandingPage

  const LandingPageBody = layoutToTemplate[layout]

  return (
    <Layout pathname={location.pathname}>
      <SEO contentfulPage={contentfulLandingPage} url={location.href} />
      <Header isInverted={layoutToInverted(layout)} />
      <div css={theme => ({ marginBottom: theme.space[15] })}>
        <LandingPageBody contentfulLandingPage={contentfulLandingPage} />
      </div>
      <Footer />
    </Layout>
  )
}

LandingPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default LandingPage

export const pageQuery = graphql`
  query LandingPage($id: String!) {
    contentfulLandingPage(id: { eq: $id }) {
      name
      slug
      layout
      landingPageType
      title
      subtitle {
        subtitle
      }
      illustration {
        title
        fluid(maxWidth: 1440) {
          ...GatsbyContentfulFluid
        }
        file {
          url
        }
      }
      socialMediaImage {
        file {
          url
        }
      }
      primaryCopy {
        childMarkdownRemark {
          html
        }
      }
      secondaryCopy {
        childMarkdownRemark {
          html
        }
      }
      formHeader {
        formHeader
      }
    }
  }
`
