import React from "react"

import { guideContentBlockStyles, articleStyles } from "../../utils/styles"
import checkmark from "../../assets/checkmark.svg"

const Copy = ({ html }) => (
  <div
    className="lp-content"
    css={{
      ...guideContentBlockStyles,
      ...articleStyles,
      "& .lp-content ul": {
        listStyleImage: `url(${checkmark})`,
      },
    }}
    dangerouslySetInnerHTML={{
      __html: html,
    }}
  />
)

export default Copy
