import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

import LandingPageType from "./landing-page-type"
import Copy from "./copy"
import Title from "../../components/hero/title.js"
import Subtitle from "../../components/hero/subtitle.js"
import HubspotForm from "../../components/hubspot-form"
import Container from "../../components/container"
import HeroContainer from "../../components/containers/hero"
import PullBelowHeader from "../../components/containers/pull-below-header"
import SkewedBackground from "../../components/decoration/skewed-background"

const TwoColumnDarkHeaderLandingPage = ({ contentfulLandingPage }) => {
  const {
    landingPageType,
    title,
    subtitle,
    illustration,
    primaryCopy,
    secondaryCopy,
    formHeader: { formHeader },
    form,
  } = contentfulLandingPage

  return (
    <Fragment>
      <PullBelowHeader>
        <HeroContainer
          isInverted
          css={theme => ({
            backgroundColor: theme.colors.purple[90],
            position: `relative`,
          })}
        >
          <Container>
            <div
              css={theme => ({
                width: `100%`,
                [theme.mediaQueries.desktop]: {
                  width: `60%`,
                  marginRight: theme.space[11],
                },
              })}
            >
              {landingPageType && (
                <LandingPageType isInverted>{landingPageType}</LandingPageType>
              )}
              <Title isInverted>{title}</Title>
              {subtitle && <Subtitle isInverted>{subtitle.subtitle}</Subtitle>}
            </div>
          </Container>
          <div css={{ paddingTop: illustration ? `160px` : `100px` }}>
            <SkewedBackground shape="wave" />
          </div>
        </HeroContainer>
      </PullBelowHeader>

      <Container
        css={theme => ({
          marginTop: illustration ? `-160px` : theme.space[7],
          zIndex: 10,
        })}
      >
        <div
          css={theme => ({
            display: `flex`,
            flexDirection: `column`,
            [theme.mediaQueries.desktop]: { flexDirection: `row` },
          })}
        >
          <div
            css={theme => ({
              width: `100%`,
              maxWidth: `100%`,
              [theme.mediaQueries.desktop]: {
                width: `60%`,
                marginRight: theme.space[11],
                flexShrink: 0,
              },
            })}
          >
            {illustration && (
              <Img
                fluid={illustration.fluid}
                alt={illustration.title}
                css={theme => ({ marginBottom: theme.space[7] })}
              />
            )}
            {primaryCopy && (
              <Copy html={primaryCopy.childMarkdownRemark.html} />
            )}
            {secondaryCopy && (
              <Copy html={secondaryCopy.childMarkdownRemark.html} />
            )}
          </div>
          <div
            css={theme => ({
              backgroundColor: theme.colors.white,
              borderRadius: theme.radii[2],
              border: `1px solid ${theme.colors.standardLine}`,
              padding: theme.space[9],
              position: `relative`,
              top: 0,
              [theme.mediaQueries.desktop]: {
                top: `-200` ? `-200` : `-250`,
              },
            })}
          >
            <h3 css={theme => ({ color: theme.colors.gatsby })}>
              {formHeader}
            </h3>
            <HubspotForm
              {...form}
              singleColumn
              css={theme => ({
                "& .hs-form": {
                  padding: 0,
                  marginTop: theme.space[4],
                  marginBottom: 0,
                },
              })}
            />
          </div>
        </div>
      </Container>
    </Fragment>
  )
}

TwoColumnDarkHeaderLandingPage.propTypes = {
  contentfulLandingPage: PropTypes.object.isRequired,
}

export default TwoColumnDarkHeaderLandingPage
